<script>
  

  import useVuelidate from "@vuelidate/core";
  import { mapActions} from "vuex";
  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';

  //import userSearchModelWS  from "@/components/widgets/worksites/userSearchModelWS";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'prodottiDaConsegnare',
    props: [
      'type',
      'projectID',
      'worksiteID',
      'worksiteOrdersID',
      'taskID',
      'interventionID',
      'data',
    ],
    setup() {
      moment.locale('it')
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Prodotti progetto",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        n_ordersResults:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        pageOrders: 1,
        perPageOrders: 10,
        responseOrdersList: [
        ],
        
        select:'all',
        search:'',
        defaultOptions: {
          animationData: []
        },
        defaultOptions1: {
          animationData: []
        },
        
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        dataEdit:{},
        checked:[],
        isAllSelected: false,
        worksiteOrder:{},
        parentCart:{},
        minDate: moment().format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY')+ '-12-31',
        minDateEnd:moment().format('YYYY-MM-DD'),
        maxDateEnd: moment().format('YYYY') + '-12-31',
      };
    },
    components: {
    },
    computed: {
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      ...mapActions('ecommercesettings', ['addItemToOrder', 'getCartByOrd']),
      convertDatetime(date){
        if (date){
          return  moment.unix(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.worksiteOrder.dataInizioTrasporto
          this.worksiteOrder.dataFineTrasporto = this.worksiteOrder.dataInizioTrasporto
          this.updateDelivery()
        }
        if (type=='end'){
          this.minDateEnd = this.worksiteOrder.dataInizioTrasporto
          this.updateDelivery()
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      selectAllCats () {
        if (this.isAllSelected) {
            this.checked = []
            this.isAllSelected = false
        } else {
            this.checked = []
            for (var i in this.responseOrdersList) {
            this.checked.push(this.responseOrdersList[i]._id)
            }
            this.isAllSelected = true
        }
      },

      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      async fullSearch(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}intervention-reports/search/products/fulltext`,
            { 
              params: { 
                q: this.qSearch,
              },
              headers: { 'authorization':this.tk} 
            }
        )
        .then((response) => {
            if (response.data)  {
            this.resultsList = response.data
            }
        })
      },
      controllaRighe(id,qta){
        return `${id}-${qta}`
      },
      getProcCarts(){
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}orders/carts/delivery/parent`, 
          { 
            id: this.$route.params.id,
            codArt:this.$route.params.codart,
            status: 'processing',
            perPage: this.perPage,
            page: this.page,
          }, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
         
            if (response.data) {
                this.worksiteOrder = response.data.worksiteOrder
                this.parentCart = response.data.parentCart
                this.responseOrdersList = response.data.childCarts.data


                let n = 0
                this.checked=[]
                for (let i of this.responseOrdersList) {
                    this.checked.push(i._id)
                    if (i.detail_item.qta) {
                        n+=i.detail_item.qta
                    }
                }

                if (this.responseOrdersList) {
                    this.n_ordersResults = n
                } else {
                    this.n_ordersResults = 0
                }
                
                this.paginated =  this.responseOrdersList
                /*
                if(this.parentCart.detail_item){
                    if(this.parentCart.detail_item.qta>1&&this.n_ordersResults==0) {
                       this.getSerials()
                    }
                }
                */

            }
        
        })
      },

      prepProcOrder(){

        if (this.worksiteOrder.causaleDiTrasporto!=''&&this.worksiteOrder.descPorto!=''&&this.worksiteOrder.aspettoBeni!=''&&this.worksiteOrder.trasportoAMezzo!=''&&this.worksiteOrder.dataInizioTrasporto!=false&&this.worksiteOrder.oraInizioTrasporto!=false ) {
            Swal.fire(
            {
                title: this.$t("t-sendorder-confirm-setting-title"),
                text: this.$t("t-sendorder-confirm-setting-text"),
                icon: this.$t("t-sendorder-confirm-setting-icon"),
                showCancelButton: true,
                cancelButtonText: this.$t("t-sendorder-confirm-cancel-button-text"),
                cancelButtonColor: this.$t("t-sendorder-confirm-setting-cancelButtonColor"),
                confirmButtonColor: this.$t("t-sendorder-confirm-setting-confirmButtonColor"),
                confirmButtonText: this.$t("t-sendorder-confirm-setting-confirmButtonText")
            }
            ).then((result) => {
                if (result.value) {
                    this.processingOrder()
                }
            });
        } else {
            alert('Attenzione: devi indicare causale e tutti i dati relativi al trasporto...')
        }

      },
      processingOrder(){
      
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}orders/carts/processes/delivery`, 
        {
          worksiteOrdersID: this.worksiteOrder._id,
          projectID: this.projectID,
          cartsList:this.checked,
        }, 
        {
          headers: { authorization:this.tk},
          onUploadProgress: (event) =>
          {
            const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
            let timerInterval;
            Swal.fire({
              title: "Caricamento...",
              timer: percentUploaded,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('Caricamento...')
              }
            });

          },
      } )
        .then((response)=>{
          
          if (response.data) {

            Swal.fire({  
              title: this.$t("t-msg-processing-order-init") , 
              text: this.$t("t-msg-processing-order-init-content"), 
              icon:"success",              
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000
            })
            alert(this.worksiteOrder._id)
            this.sendOrderToERP(this.worksiteOrder._id)
          }
      })
    },
    
    sendOrderToERP(woid){
      this.axiosInterceptor()
        axios.post(`${this.UrlServer}orders/send-worksiteorders-erp`, 
          {
            worksiteOrdersID:woid,
          }, 
          {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
               
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            const childComponent = this.$refs.getProcCarts;
            if (response.data) {
              if (childComponent) {
                childComponent.getProcCarts();
              } else {
                  console.error('Child component reference is undefined.');
              }
              Swal.fire({  
                title: this.$t("t-msg-processing-order-init") , 
                text: this.$t("t-msg-processing-order-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
            }
        })
    },
      addElement(params){
        let date = moment().format('YYYY/MM/DD')
        params.projectID = this.projectID
        params.productID = params._id
        
        params.area = 'project'
        params.year = date.split('/')[0]
        params.day = date.split('/')[2]
        params.month = date.split('/')[1]
        params.number = 1
        /*
        if ( moment().day() <10 ){
            params.day =`0${moment().day()}`
        } else {
            params.day = moment().month()
        }
        */
        params.date_tmp = moment().unix()
        params.createdBy = this.datastore.auth.currentUser
        if (params._id){
          delete params._id
        }

        this.axiosInterceptor()
        axios.post(`${this.UrlServer}intervention-reports/add-product`, params, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             // let timerInterval;
              console.log(percentUploaded)
              /*
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
                
              }).then((result) => {
                
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });
              */

            },
        } )
          .then((response)=>{
            if (response.data) {
              //this.getList()
              this.resultsList = []
              this.search=''
              Swal.fire({  
                title: this.$t("t-msg-element") , 
                text: this.$t("t-msg-element-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
            }
        })
      },

      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        //this.getList()
        this.getProcCarts()
      },
      paginatedOrders(response) {
        let page = this.pageOrders;
        let perPage = this.perPageOrders;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return response.slice(from, to);
      },
      paginateOrdersAct(pg){
        this.pageOrders = pg
        this.getProcCarts()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
      },
      selectItem(params){
          let array = this.responseList.filter(function(el){
            return el._id == params._id
          })
          if (array.length==0){
            this.addElement(params)
          } else {
            Swal.fire({
              title:"Prodotto già presente",
              text:"Prodotto che hai selezionato è già presente!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
      },
      updateDelivery(){
        let data = {
            causaleDiTrasporto:this.worksiteOrder.causaleDiTrasporto,
            descPorto:this.worksiteOrder.descPorto,
            aspettoBeni:this.worksiteOrder.aspettoBeni,
            trasportoAMezzo: this.worksiteOrder.trasportoAMezzo,
            dataInizioTrasporto:this.worksiteOrder.dataInizioTrasporto,
            oraInizioTrasporto:this.worksiteOrder.oraInizioTrasporto,
            dataFineTrasporto:this.worksiteOrder.dataFineTrasporto,
            oraFineTrasporto:this.worksiteOrder.oraFineTrasporto,
        }
        this.axiosInterceptor()
          axios.put(`${this.UrlServer}worksites/delivery/${this.worksiteOrder._id}`, data, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (!response.data) {
                alert('Non è stato possibile aggiornare i dati')
              }
          }) 
      },
      updateSerial(id,serial){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}worksites/delivery/serial/${id}`, {serialCode:serial}, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (response.data) {
                document.getElementById(`serial-${serial}`).style.color = "green"
              }
          })
      },
      getCart(){
        if ( localStorage.getItem("orx") ) {
          this.getCartByOrd(localStorage.getItem("orx"))
        }
      },
      addTC(gcode, codMarca, qty){
        moment.locale('it')
        let momentCode = moment().unix()
        let orx = false
      
        if ( localStorage.getItem("orxpro") ) {
          orx = localStorage.getItem("orxpro")
        } else {
          localStorage.setItem("orxpro", orx)
        }
        let sr = 'mov-magazzino'

        let data = { 
          productCode: gcode,
          userId: this.data.customerDetails._id,
          projectID: this.data._id,
          cuuid:momentCode,
          orx:orx,
          salesRoute:sr,
          qta: qty,
        }
        this.addItemToOrder(data)
      },
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        }
      }
      this.getProcCarts()
    }
  };
</script>

<template>
  <div class="card m-5">
        <div class="back-grey-0">
            <div class="row pb-2">
                <div class="col-lg-2 col-md-12 pr-5">
                     <label class="form-label pr-5">Codice Prodotto</label>
                    <template v-if="parentCart.detail_item">
                        <strong>{{ parentCart.gcode }}</strong>
                    </template>
      
                  </div>   
                  <div class="col-lg-6  col-md-12">
                    
                        <label for="descPorto" class="form-label pr-5 ">Nome prodotto</label>
                        <template v-if="parentCart.detail_item">
                            <strong>{{ parentCart.detail_item.Nome }}</strong>
                        </template>                        
                  </div> 
            </div>
            <div class="row pt-2 pb-5 pt-2">
                <div class="col-lg-6 col-md-12 pr-5">
                     <label class="form-label pr-5">Descrizione:</label><br>
                     <template v-if="parentCart.detail_item">
                            <strong>{{ parentCart.detail_item.Descrizione }}</strong>
                        </template>   
                    </div>   
                  
            </div>
            
        </div>
        <div class="card-header back-info-light">
            <h5 class="card-title mb-0 bold-white"> <i class="mdi mdi-view-list-outline"></i> Numeri seriali associati</h5>
        </div>
        <div class="card-body">
          <div class="card-header ">
            <div class="row align-items-center">
              <div class="col mb-5">
                <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">

                  <li class="nav-item">
                    <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                       Lista
                      <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_ordersResults}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-auto mt-5">

              </div>
              <div class="col-auto mt-5">
               
               
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-card mt-5">
              <div class="row thRow  ">
                <div class="col text-left">
                  Nome
                </div>
                <div class="col text-left">
                  Codice
                </div>
                <div class="col text-left">
                  Quantità
                </div>
                <div class="col text-left">
                 Seriale
                </div>
                <div class="col text-left">
                 Azione
                </div>
              </div>
              
              <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseOrdersList" :key="index" >
              
                <div class="col text-left" v-if="item.doc_type=='row-serial'">
                  <strong>{{ item.detail_item.Nome}}</strong>
                  <br><i>{{ item.CodMarca }}</i>
                </div>
                <div class="col text-left" v-if="item.doc_type=='row-serial'">
                  <strong>{{ item.gcode}}</strong>
                 
                </div>
                <div class="col text-left" v-if="item.doc_type=='row-serial'">
                  {{ item.detail_item.qta  }}
                </div>

                <div class="col" v-if="item.doc_type=='row-serial'">
                   <input type="text" class="form-control" :id="`serial-${item._id}`" v-model="item.serialCode" @blur="updateSerial(item._id,item.serialCode)" />
                  
                  </div>
                <div class="col" v-if="item.doc_type=='row-serial'">
                
                </div>
              </div>
              
            </div>
                <div>
                  <div class="table-responsive table-card mb-1">

                    <div class="noresult" style="display: none">
                      <div class="text-center">
                        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                          :height="75" :width="75" />
                        <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                        <p class="text-muted mb-0">
                        
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex tRow justify-content-end mt-3" >
                        <div class="col-lg-2 col-md-12 text-right">
                            <label class="pt-2">Risultati per pagina:</label>
                        </div>        
                        <div class="col-lg-1 col-md-12 ">
                            <div class="pl-10 pr-20">
                                <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                    <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 ">
                            <div class="pagination-wrap hstack gap-2">
                            <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                                Precedenti
                            </a>
                            <ul class="pagination listjs-pagination mb-5 pr-10 pl-10" >
                                <li :class="{
                                        active: index == page,
                                        disabled: index == '...',
                                        }" v-for="index in paginated.total_pages" :key="index">
                                    <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                                </li>
                            
                            </ul>
                            <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                            Successivi
                            </a>
                            </div>
                        </div>
                  </div>
            </div>
      </div>
    </div>
  </div>
</template>